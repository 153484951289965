export const TeamProfile = [
  {
    id: 1,
    name: 'Vanessa Carrignton',
    position: 'CEO of pgi transport',
    avatar: require('../assets/vanessa.jpg'),
  },
  {
    id: 2,
    name: 'Harold Mayhem',
    position: 'head of transport',
    avatar: require('../assets/ali.jpg'),
  },
  {
    id: 3,
    name: 'Amanda Brown',
    position: 'heaad of logistics',
    avatar: require('../assets/christie.jpg'),
  },
  {
    id: 4,
    name: 'Helga Walker',
    position: 'head of contract logistics',
    avatar: require('../assets/zazou.jpg'),
  },
  {
    id: 5,
    name: 'Chriss Morison',
    position: 'head of technical suppport',
    avatar: require('../assets/james.jpg'),
  },
  {
    id: 6,
    name: 'Jeremy Lynch',
    position: 'CEO of pgi transport',
    avatar: require('../assets/jeremy.jpg'),
  },
];
