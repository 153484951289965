import ReqQuote from '../components/ReqQuote';

const RqsQoute = () => {
  return (
    <>
      <ReqQuote />
    </>
  );
};

export default RqsQoute;
